@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login h2{
    text-align:center;
    margin-bottom: 24px;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}