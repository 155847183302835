.list-group-item-members-header {
    display: flex;
}

.form-member-container {
    margin-bottom: 24px
}

#form-group-members-add {
    display: flex;
    margin-bottom: 0;
}

div .members-remove {
    display: flex;
}

.list-group-item-members-remove {
    width: 100%;
}

.list-group-item-edit {
    width: 65px;
}

div#form-group-members-add {
    margin: 8px 0;
}

button.listSideButton{
    margin: 1px;
}

button.saveList{
    margin: 16px 0;
}