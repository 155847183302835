.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

@media all and (min-width: 1400px) {
  .App {
    width: 1370px;
  }
}
@media all and (min-width: 1700px) {
  .App {
    width: 1670px;
  }
}