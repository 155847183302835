.NewLeague form {
    padding-bottom: 15px;
}

.NewLeague .form-group {
    margin-bottom: 24px;
}

.NewLeague form textarea {
    height: 300px;
    font-size: 24px;
}