td.score-ebad {
  background-color:rgba(192,57,43, 0.67);
}
td.score-vbad {
  background-color:rgba(236,100,75, 0.67);
}
td.score-pbad {
  background-color:rgba(241,169,160, 0.67);
}
td.score-bad {
  background-color:rgba(252,214,112, 0.67);
}
td.score-ok {
  background-color:rgba(255,246,143, 0.67);
}
td.score-good {
  background-color:rgba(200,247,197, 0.67);
}
td.score-pgood {
  background-color:rgba(102,204,153, 0.67);
}
td.score-vgood {
  background-color:rgba(0,177,106, 0.47);
}
td.score-egood {
  background-color:rgba(0,177,106, 0.67);
}
td.score-table-heading {
  font-weight: bold;
  min-width: 85px;
}
